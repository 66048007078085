/** THIS FILE MUST FOLLOW NODEJS SEMANTICS */

/** Template
const PageNameMeta = {
  path: "", // required, with leading and trailing forward slash
  title: "", // required
  description: "", // required
  // sitemap only required if this page should be listed on the sitemap
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2022-01-15", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
  // socialImage not required
  socialImage: {
    src: "", // require("path/to/image.png")
    width: "100px",
    height: "100px",
  },
}
*/

const NotFoundMeta = {
  path: "/404/",
  title: "Oops! That page can't be found.",
  description: "",
}

const IndexMeta = {
  path: "/",
  title: "Slice Insurance",
  description:
    "Slice is the premier technology company for 100% digital, small business insurance. Backed by top global insurers and powered by advanced AI/ML and large language models, we offer seamlessly automated, online and hassle-free insurance solutions.",
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2024-01-27", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const SliceMindMeta = {
  path: "/slice-mind/",
  title: "Slice Mind",
  description:
    "Slice Mind and our Insurance Cloud Services (ICS) platform utilize AI, ML, behavioral science, and Large Language Models to deliver on-demand small business insurance products.",
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2024-01-28", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const AgentsMeta = {
  path: "/agents/",
  title: "Agents",
  description:
    "Experience 100% digital small business insurance. Seamlessly automated, online, and hassle-free. Create your account or log in to access Slice.", // required
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2023-04-05", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const MobilityMeta = {
  path: "/mobility-insurance/",
  title: "Mobility Insurance",
  description:
    "Mobility insurance technology supporting safer driving and better protection for vehicles and drivers.", // required
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2022-01-21", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const AboutMeta = {
  path: "/about/",
  title: "About Us",
  description:
    "As a forward-thinking technology and insurance company, we fill protection gaps for small businesses through innovative, transparent products and a cloud-based platform, leveraging artificial intelligence, machine learning, and large language models.", // required
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2024-01-27", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const CareersMeta = {
  path: "/careers/",
  title: "Work with Us",
  description:
    "Explore career opportunities with Slice and be a part of shaping and innovating insurance.",
  sitemap: {
    priority: 0.7,
    changeFrequency: "yearly",
    lastModified: "2022-01-27",
  },
}

const PressMediaMeta = {
  path: "/press-media/",
  title: "Media, Press, and Podcasts",
  description:
    "Stay up-to-date about the latest developments at Slice through our media coverage and podcasts.", // required
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2023-04-05", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const ContactMeta = {
  path: "/contact/",
  title: "Contact",
  description: "Reach out to Slice Labs with any questions or comments.", // required
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2022-01-22", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const LicenseNumbersMeta = {
  path: "/license-numbers/",
  title: "Slice License Numbers",
  description:
    "View our United States license numbers to ensure transparency and trust.",
  sitemap: {
    priority: 0.1,
    changeFrequency: "yearly",
    lastModified: "2022-01-23",
  },
}

const PrivacyMeta = {
  path: "/privacy-policy/",
  title: "Privacy Policy",
  description:
    "Understand how Slice handles your data by reviewing our Privacy Policy.",
  sitemap: {
    priority: 0.5,
    changeFrequency: "yearly",
    lastModified: "2022-01-26",
  },
}

const TermsMeta = {
  path: "/terms-of-use/",
  title: "Terms of Use",
  description:
    "Familiarize yourself with the terms governing your use of Slice's services.",
  sitemap: {
    priority: 0.5,
    changeFrequency: "yearly",
    lastModified: "2022-01-28",
  },
}

const CookieMeta = {
  path: "/cookie-policy/",
  title: "Cookie Policy",
  description:
    "Learn about the cookies used on Slice's website and their purpose.",
  sitemap: {
    priority: 0.5,
    changeFrequency: "yearly",
    lastModified: "2022-01-28",
  },
}

const SitemapMeta = {
  path: "/sitemap/",
  title: "Sitemap",
  description: "Explore the structure of Slice's website.",
  sitemap: {
    priority: 0.5,
    changeFrequency: "yearly",
    lastModified: "2023-04-05",
  },
}

const FaqMeta = {
  path: "/faq/",
  title: "Frequently Asked Questions",
  description:
    "Get answers to common queries about Slice’s products and services.",
  sitemap: {
    priority: 0.5,
    changeFrequency: "yearly",
    lastModified: "2022-06-02",
  },
}

const ContractorsGLMeta = {
  path: "/contractors-general-liability/",
  title: "Contractors General Liability",
  description:
    "Learn about our AM Best A++ rated small business insurance for artisan, general, and remodeler contractors.",
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2023-04-05", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const WorkersCompMeta = {
  path: "/workers-compensation/",
  title: "Workers’ Compensation",
  description:
    "Learn about Slice’s all-digital, online Workers’ Compensation insurance tailored for small businesses.",
  sitemap: {
    priority: 0.7, // page priority weight, 0.0 - 1.0
    changeFrequency: "yearly", // one of: always, hourly, daily, weekly, monthly, yearly, never
    lastModified: "2023-04-05", // MUST be in YYYY-MM-DD format, make sure to update when changing content
  },
}

const Sitemap = [
  IndexMeta,
  SliceMindMeta,
  ContractorsGLMeta,
  WorkersCompMeta,
  AgentsMeta,
  AboutMeta,
  CareersMeta,
  PressMediaMeta,
  FaqMeta,
  ContactMeta,
  LicenseNumbersMeta,
  PrivacyMeta,
  TermsMeta,
  CookieMeta,
  SitemapMeta,
]

module.exports = {
  NotFoundMeta,
  IndexMeta,
  SliceMindMeta,
  ContractorsGLMeta,
  WorkersCompMeta,
  AboutMeta,
  MobilityMeta,
  LicenseNumbersMeta,
  ContactMeta,
  PressMediaMeta,
  AgentsMeta,
  PrivacyMeta,
  SitemapMeta,
  CareersMeta,
  CookieMeta,
  TermsMeta,
  FaqMeta,
  Sitemap,
}
